

//buttons //contactTitle
export const getInTouch = "Get in Touch";
export const sendMessage = "Send Message";

//titles
export const aboutUs = "About Us";
export const products = "Products";
export const customers = "Our Customers";
export const services = "Products and Services";
export const pastProjects = "Past and Ongoing Projects";
export const subTitleContacts =
  " Got a question? We'd love to hear from you. Send us a message and we'll respond as soon as possible.";
export const name = "Name";
export const email = "Email Address";
export const contactNumber = "Contact Number";
export const message = "Message";
export const government = "Government";
export const privateSector = "Private Sector";
export const ngo = "NGO";
export const retailers = "Retail Convenience Shop";

//imageUrlAboutUs
export const imageUrl =
  "https://cdn.pixabay.com/photo/2015/01/08/18/27/startup-593341_1280.jpg";

//contact us
export const contact = [
  {
    name: "customerservices",
    url: require("./images/contact/customerservices.png"),
  },
]
 


//navbar
export const navbarData = [
  {
    title: "Home",
    url: "home",
    status: true,
  },
  {
    title: "About",
    url: "about",
    status: false,
  },
  {
    title: "Product & Services",
    url: "service",
    status: false,
  },
  {
    title: "Past & Ongoing Projects",
    url: "project",
    status: false,
  },
  {
    title: "Contact Us",
    url: "contact",
    status: false,
  },
];

//heroPage
export const mainTitle = "Supplying your everyday needs, hassle-free !";
export const subTitle = "We ";
export const subTitle2 = " ";
export const subTitle_array = [
  "Supply Pharmaceutical and Medical Products",
  "Supply To Retailers",
  "Provide Transportation and Logistic Services",
];

//brands
export const brandsLogo = [
  {
    name: "3M-logo",
    url: require("./images/logo/3m.png"),
  },
  {
    name: "Cranberry-logo",
    url: require("./images/logo/Cranberry.png"),
  },
  {
    name: "himaya-logo",
    url: require("./images/logo/himaya.png"),
  },
  {
    name: "jovian-logo",
    url: require("./images/logo/jovian.png"),
  },
  {
    name: "juschek-logo",
    url: require("./images/logo/juschek.png"),
  },
  {
    name: "kossan-logo",
    url: require("./images/logo/kossan.png"),
  },
  {
    name: "longsee-logo",
    url: require("./images/logo/longsee.png"),
  },
  {
    name: "medipro-logo",
    url: require("./images/logo/medipro.png"),
  },
  {
    name: "neutrovis-logo",
    url: require("./images/logo/neutrovis.png"),
  },
  {
    name: "newgene-logo",
    url: require("./images/logo/newgene.png"),
  },
  {
    name: "nitecore-logo",
    url: require("./images/logo/nitecore.png"),
  },
  {
    name: "noxxa-logo",
    url: require("./images/logo/noxxa.PNG"),
  },
  {
    name: "olympus-logo",
    url: require("./images/logo/olympus.PNG"),
  },
  {
    name: "philip-logo",
    url: require("./images/logo/philip.png"),
  },
  {
    name: "salixium-logo",
    url: require("./images/logo/salixium.PNG"),
  },
  {
    name: "steriline-logo",
    url: require("./images/logo/steriline.png"),
  },
  {
    name: "tajima-logo",
    url: require("./images/logo/tajima.JPG"),
  },
  {
    name: "topGlove-logo",
    url: require("./images/logo/topGlove.png"),
  },
];

export const brandsLogo2 = [
  {
    name: "axia",
    url: require("./images/logo/axia.png"),
  },
  {
    name: "saga",
    url: require("./images/logo/saga.png"),
  },
  {
    name: "kia-carnival",
    url: require("./images/logo/kiacarnival.png"),
  },
  {
    name: "land-crusier",
    url: require("./images/logo/landcruiser.png"),
  },
  {
    name: "vellfire",
    url: require("./images/logo/vellfire.png"),
  },
  {
    name: "van",
    url: require("./images/logo/van.png"),
  },
  {
    name: "lorry",
    url: require("./images/logo/lorry.png"),
  },
  {
    name: "ship",
    url: require("./images/logo/ship.png"),
  },
  {
    name: "plane",
    url: require("./images/logo/plane.png"),
  },
];

export const brandsLogo3 = [
  {
    name: "aducktive-logo",
    url: require("./images/logo/aducktive.png"),
  },
  {
    name: "saunprathumthip-logo",
    url: require("./images/logo/saunprathumthip.png"),
  },
  {
    name: "readycup-logo",
    url: require("./images/logo/readycup.png"),
  },
  {
    name: "tokku-logo",
    url: require("./images/logo/tokku.png"),
  },
  {
    name: "hongshan-logo",
    url: require("./images/logo/hongshan.png"),
  },
];

//about
export const aboutData =
  "F2S started as healthcare supply for a lifestyle intervention program back in 2012 under Fit Two Shape Enterprise. \n \n The company continue to supply household electrical goods to end users and moved to medical consumables from 2020 onwards.\n\n Now F2S has supplied extensive consumables goods from oil and gas goods to medical and healthcare goods to clients from industrial to healthcare providers across Sabah and Sarawak \n\n";

export const aboutDataPic = [
  {
    name: "About",
    url: require("./images/about/F2SlogoBAK.png"),
    desc: "Fit2Shape Logo",
  }
    
]
  
//product
export const productsData = [
  {
    name: "Medical Consumables",
    url: require("./images/products/Medical Consumables.jpg"),
    desc: "Medical Consumables",
  },
  {
    name: "Medical Appliances",
    url: require("./images/products/Medical Appliances.jpg"),
    desc: "Medical Appliances",
  },
  {
    name: "Lab Equipments",
    url: require("./images/products/Lab Equipments.jpg"),
    desc: "Lab Equipments",
  },
  {
    name: "Industrial Consumables",
    url: require("./images/products/Industrial Consumables.jpg"),
    desc: "Industrial Consumables",
  },
  {
    name: "Oil & Gas Consumables",
    url: require("./images/products/O&G Consumables.jpg"),
    desc: "Oil & Gas Consumables",
  },
  {
    name: "Over-The-Counter Products",
    url: require("./images/products/OTC.jpg"),
    desc: "Over-The-Counter Products",
  },
];

//customers
export const custGov =
  "Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor.";
export const custPrivate =
  "Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor.";
export const custNGO =
  "Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor.";

//services
export const servicesData = [
  {
    name: "Bulk Purchasing",
    url: require("./images/services/bulkPurchasing.png"),
    desc: "Do reach out to us for any inquiries regarding bulk purchase or wholesale of consumables",
  },
  {
    name: "Logistic Options",
    url: require("./images/services/logisticOptions.JPG"),
    desc: "We can deliver your goods Door-to-Door either by premium service air freight, standard air freight or sea freight to Sarawak & Sabah, we have options for you to choose from at affordable price!",
  },
  {
    name: "IT Solutions",
    url: require("./images/services/itSolution.JPG"),
    desc: "Contractual Delivery Service for Air Freight, Sea Freight and Land Transport. We also provide E-Hailing Hosting, Car Rental and Chauffeur Service.",
  },
];

//prodserv
export const prodservData = [
  {
    name: "Supplies",
    url: require("./images/services/bulkPurchasing.png"),
    desc: "Your premier supplier of quality Pharmaceutical Goods, advanced Lab Equipment, and diverse Over-The-Counter Products for optimal health and well-being.",
  },
  {
    name: "Food & Beverages",
    url: require("./images/services/AMO50129.jpg"),
    desc: "We are a dynamic retail entity specializing in the import and export of premium food and beverage products.",
  },
  {
    name: "Transportation & Logistics",
    url: require("./images/services/logistic.jpg"),
    desc: "Contractual Delivery Service for Air Freight, Sea Freight and Land Transport. We also provide E-Hailing Hosting, Car Rental and Chauffeur Service.",
  },
];

//pastProjects
export const pastProjectsData = [
  {
    name: "1",
    url: require("./images/pastProjects/1.jpeg"),
    desc: "",
  },
  {
    name: "2",
    url: require("./images/pastProjects/2.jpeg"),
    desc: "",
  },
  {
    name: "3",
    url: require("./images/pastProjects/3.jpeg"),
    desc: "",
  },
  {
    name: "4",
    url: require("./images/pastProjects/4.jpeg"),
    desc: "",
  },
  {
    name: "Medical Consumables",
    url: require("./images/pastProjects/SarawakDentalClinics.JPG"),
    desc: "Supply and Service Air Decontamination Units for Sarawak Dental Clinics for 5 years",
  },
  {
    name: "Medical Appliances",
    url: require("./images/pastProjects/yayasan_petronas.png"),
    desc: "Awarded by Yayasan PETRONAS to supply Patient Monitors and Jumpsuit for donation to Jabatan Kesihatan Negeri Sabah",
  },
  {
    name: "Lab Equipments",
    url: require("./images/pastProjects/7E DELIVERY.jpg"),
    desc: "Supply of Covid Test Kit to all 7-Eleven Retail Outlets in Sabah and Sarawak",
  },
  {
    name: "Industrial Consumables",
    url: require("./images/pastProjects/CovidTestKit.png"),
    desc: "Supplies of Covid Test Kit and other consumables during Sarawak State Election",
  },
  {
    name: "Oil & Gas Consumables",
    url: require("./images/pastProjects/RTDSarawak.png"),
    desc: "Supplies of Medical Consumables to Road Transport Department Sarawak for 2 years",
  },
  {
    name: "Over-The-Counter Products",
    url: require("./images/pastProjects/BumiAgents_Pharmacies.JPG"),
    desc: "Supply of Medical Consumables to Bumi Agents and Pharmacies in Sarawak",
  },
  {
    name: "Over-The-Counter Products",
    url: require("./images/pastProjects/LabEquiments.JPG"),
    desc: "Supply and Commissioning of Laboratory Equipments and Consumables for Sarawak Pathology Laboratory",
  },
  {
    name: "Over-The-Counter Products",
    url: require("./images/pastProjects/Add on gambar wiith caption.jpg"),
    desc: "Heavily involved with various NGOs with medical supplies during MCO and lockdown in Malaysia",
  },
];
