import React, { useEffect, useState } from "react";
import { brandsLogo, brandsLogo2, brandsLogo3 } from "../Data";
import AOS from "aos";

export default function Brands() {
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(9);
  const [showFirstSet, setShowFirstSet] = useState(true);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const [animation, setAnimation] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimation("animate__animated animate__fadeIn");
      setTimeout(() => {
        setAnimation("");
      }, 3000);

      // Update the start and end indexes
      if (startIndex < brandsLogo.length / 2) {
        setStartIndex((startIndex + 9) % brandsLogo.length);
        setEndIndex((endIndex + 9) % brandsLogo.length);
      } else {
        setStartIndex((startIndex - 9 + brandsLogo.length) % brandsLogo.length);
        setEndIndex((endIndex - 9 + brandsLogo.length) % brandsLogo.length);
      }
      setShowFirstSet(!showFirstSet);
    }, 3000);
    return () => clearInterval(interval);
  }, [startIndex, endIndex, showFirstSet]);

  const visibleBrands = showFirstSet
    ? brandsLogo.slice(startIndex, endIndex)
    : [...brandsLogo.slice(-9)];

  const visibleBrands2 = brandsLogo2;
  const visibleBrands3 = brandsLogo3;

  return (
    <div id="brands" className="w-full px-6 pb-10" data-aos="fade-up">
      <div id="brands">
        <h1
          data-aos="flip-down"
          className="text-1xl md:text-2xl font-bold mb-12 text-center text-black mt-10" 
        >
          MEDICAL PRODUCT BRANDS
        </h1>
      </div>
      <div className="flex justify-center bg-white p-6 rounded-lg shadow-xl">
        <div className="grid grid-cols-3 md:grid-cols-9 gap-8">
          {visibleBrands.map((item, index) => (
            <div
              key={index}
              className={`flex justify-center items-center ${animation}`}
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <img
                src={item.url}
                alt={item.name}
                className="h-13 object-cover"
              />
            </div>
          ))}
        </div>
      </div>
      <div id="brands2">
        <h1
          data-aos="flip-down"
          className="text-1xl md:text-2xl font-bold mb-12 text-center text-black mt-10" 
        >
          TRANSPORTATION AND LOGISTICS
        </h1>
      </div>
      <div className="flex justify-center bg-white p-6 rounded-lg shadow-xl">
        <div className="grid grid-cols-3 md:grid-cols-9 gap-8">
          {visibleBrands2.map((item, index) => (
            <div
              key={index}
              className={`flex justify-center items-center ${animation}`}
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <img
                src={item.url}
                alt={item.name}
                className="h-13 object-cover"
              />
            </div>
          ))}
        </div>
      </div>
      <div id="brands3">
        <h1
          data-aos="flip-down"
          className="text-1xl md:text-2xl font-bold mb-12 text-center text-black mt-10" 
        >
          PANTAS FOOD
        </h1>
      </div>
    <div className="flex justify-center bg-white p-6 rounded-lg shadow-xl">
      <div className="grid grid-cols-3 md:grid-cols-5 gap-8">
        {visibleBrands3.map((item, index) => (
          <div
            key={index}
            className={`flex flex-col justify-center items-center ${animation}`}
            style={{ animationDelay: `${index * 0.1}s` }}
          >
            <img
              src={item.url}
              alt={item.name}
              className={`h-20 object-cover ${window.innerWidth >= 768 ? 'md:h-20' : ''}`}
            />
          </div>
        ))}
      </div>
    </div>

    </div>
  );
}