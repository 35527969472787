import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";

export default function FloatingButtons() {
  return (
    <div>
      <FloatingWhatsApp
        phoneNumber="+60168507347"
        accountName="Fit2Shape"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar="https://scontent.fpen1-1.fna.fbcdn.net/v/t1.18169-9/282988_189935384483967_60535770_n.png?_nc_cat=111&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeEVNWjG5ZZM6sM3uiMoyFgIinJqAQBdF8yKcmoBAF0XzNyIaTcOEKnPFEK8QSEzqE75s72RMJWxs259VkUXMtP2&_nc_ohc=HmfWPkHs_EoAX-4Ao2G&_nc_ht=scontent.fpen1-1.fna&oh=00_AfBoZu3a_YzdfZ9GDtnEN4jWFHdRcKTBE9LLldg-FUBxMA&oe=63B05399"
      />
    </div>
  );
}
