import React, { useEffect } from "react";
import { custGov, custPrivate, custNGO, customers, government, privateSector, ngo, retailers} from "../Data";
import { IconContext } from "react-icons/lib";
import { RiGovernmentFill } from "react-icons/ri";
import { HiBuildingOffice } from "react-icons/hi2";
import { HiBuildingStorefront } from "react-icons/hi2";
import { BiDonateHeart } from "react-icons/bi";
import AOS from "aos";

export default function Customers() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="mb-8 md:mb-0">
      <div id="customers" className="w-full  bg-white px-4 md:px-0">
      <section
        data-aos="fade-up"
        data-aos-duration="3000"
        className="bg-Hero bg-cover bg-center py-4 md:px-24 px-4 pb-20"
      >
        <div className="bg-yellow-600 shadow-2xl rounded-lg w-full h-auto">
          <h3 className="text-black drop-shadow-lg md:text-3xl text-4xl font-bold text-center pt-4">
            {customers}
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-10 md:px-10 md:py-10 px-10 py-4 mt-5 rounded-md justify-center">
            <div className="flex flex-col items-center">
              <IconContext.Provider value={{ size: "3em" }}>
                <RiGovernmentFill />
              </IconContext.Provider>
              <h1 className="font-semibold text-lg my-3 text-center">{government}</h1>
            </div>
            <div className="flex flex-col items-center">
              <IconContext.Provider value={{ size: "3em", color: "#33271B"}}>
                <HiBuildingOffice />
              </IconContext.Provider>
              <h1 className="font-semibold text-lg my-3 text-center">{privateSector}</h1>
            </div>
            <div className="flex flex-col items-center">
              <IconContext.Provider value={{ size: "3em", color: "#33271B"}}>
                <BiDonateHeart />
              </IconContext.Provider>
              <h1 className="font-semibold text-lg my-3 text-center">{ngo}</h1>
            </div>
            <div className="flex flex-col items-center">
              <IconContext.Provider value={{ size: "3em", color: "#33271B"}}>
                <HiBuildingStorefront />
              </IconContext.Provider>
              <h1 className="font-semibold text-lg my-3 text-center">{retailers}</h1>
            </div>
          </div>
        </div>
      </section>
    </div>
    </div>

    
  );
}

