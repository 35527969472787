import React, { useEffect } from "react";
import { prodservData, services } from "../Data";
import AOS from "aos";

export default function ProdServ() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="bg-gradient-to-b from-silver to-yellow-600 pt-0 pb-20 ">
      
      <div id="service">
        <h1
          data-aos="flip-down"
          className="text-4xl md:text-5xl font-bold mb-12 text-center text-black mt-10" 
        >
          {services}
        </h1>
      </div>
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-5">
        {prodservData.map((item, index) => (
          <div
            data-aos="zoom-in"
            className="bg-white shadow-xl rounded-lg overflow-hidden transform hover:scale-105 duration-300"
            key={index}
          >
            <div className="relative h-60">
              <img
                className="object-cover w-full h-full"
                src={item.url}
                alt={item.name}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-transparent to-gray-800 opacity-80"></div>
              <div className="absolute inset-0 flex items-center justify-center text-white">
                <h2 className="text-2xl md:text-2xl lg:text-3xl font-bold">
                  {item.name}
                </h2>
              </div>
            </div>
            <div className="px-6 py-8">
              <p className="text-lg">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  
}
